import React, { useState, useEffect } from 'react';

const CodeTimer = (props) => {
    const [seconds, setSeconds] = useState(0)
    const [isActive, setIsActive] = useState(false)
    const [startTime, setStartTime] = useState(new Date()/1000)
    useEffect(() => {
            if (seconds == 0){
                setIsActive(true)
            }
        }, [])
    function toggle() {
        setIsActive(!isActive);
    }

  
    useEffect(() => {
        let interval = null;
        if (isActive) {
        interval = setInterval(() => {
            //const s = seconds + 1
            const s = Math.round(new Date()/1000 - startTime)
            setSeconds(seconds => s);
            if (seconds > 30){
                props.setTimerFinished(true)
                clearInterval(interval);
            }
        }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    const secondsToTime = () => {
        var time = "00:00"
        const sec = 30 - seconds
        if (seconds < 3600){
            time = new Date(sec * 1000).toISOString().substring(14, 19)
        } else {
            time = new Date(sec * 1000).toISOString().substring(11, 16)
        }
        console.log("timerTime = " + sec)
        return time
    }
   

    return (
        <div className="code_timer">
            Resend in {secondsToTime()}
        </div>
    )
}

export default CodeTimer;