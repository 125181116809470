import { useHistory } from "react-router-dom";
import React, {useCallback} from 'react';
import { CSSTransition } from 'react-transition-group';


function BaseDesktopDialog(props) {
    
    return(
        <CSSTransition in={props.isShow != undefined ? props.isShow : false} timeout={50} classNames="animation_opacity" unmountOnExit>
            <div className="relative">
                <div onClick={() => props.setShow != undefined ? props.setShow(false) : {}} className="desktop_dialog_bg"></div>
                <div className="desktop_dialog_inner">
                    {props.getInnerHtml()}
                </div>
            </div>
        </CSSTransition>
    )
}
export default BaseDesktopDialog;