import '../../styles/auth.css';
import { useState, useRef, useEffect } from 'react';
import { getPhoneMaskPlaceholder, getPhoneMask } from '../../utils/Utils';
import { IMaskInput } from 'react-imask';
import { Pages, ConstParams } from '../../utils/Utils';
import { AuthApi } from '../../api/AuthApi';
import Loader from '../../custom_views/Loader';
import { useHistory } from "react-router-dom";

function LogIn(props) {
    const inputRef = useRef(null)
    const [isEmailAuth, setEmailAuth] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")
    const history = useHistory()
    useEffect(() => {
        inputRef.current.focus();
    }, [])
    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
    var isPhoneEntered = isEmailAuth ? validateEmail(email) : props.phoneNumber.length == ConstParams.phoneLength
    const auth = () => {
        setError("")
        const params = {userContact : isEmailAuth ? email : props.unmaskedPhone}
        console.log("params passwordRecovery = ", params)
        AuthApi.passwordRecovery(params, (responseData) => {
            history.push(Pages.sms_code + "?contact=" + params.userContact + "&clear_contact=" + (isEmailAuth ? email : props.phoneNumber))
        }, (responseData) => {
            setError("Could not sign you in. The " + (isEmailAuth ? "email address" : "phone number") + " you entered is not assigned to a registered user. Please register your email address via Congratz App or contact customer support at support@congratz.io")
        }, setLoading)
    }
    const toggleIsEmail = () => {
        setEmailAuth(!isEmailAuth)
        setError("")
    }
    return(
        <div className="auth_form_bg">
            <div className="auth_title">{isEmailAuth ? "Sign in with email" : "Sign in"}</div>
            <div className="auth_desc">{isEmailAuth ? "Please enter your email address and we'll send you a confirmation code" : "To confirm your identity, we’ll text a confirmation code to your phone"}<br/></div>
            <div className="auth_input_bg">
                {isEmailAuth ?
                <input className="auth_phone_input" type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email}/>
                : <IMaskInput onChange={() => {}} type="tel" inputRef={inputRef} className="auth_phone_input" mask={getPhoneMask()}  radix="." value={props.phoneNumber} unmask={false}
                onAccept={
                    (value, mask) => {
                        props.setPhone(value, mask._unmaskedValue)
                        console.log("mask._unmaskedValue = ", mask._unmaskedValue)
                    }
                } placeholder={getPhoneMaskPlaceholder()} />}
            </div>

            <div onClick={auth} style={!isPhoneEntered ? {background: 'rgba(255, 255, 255, 0.5)', boxShadow : 'none', pointerEvents : 'none'} : {}} className="auth_next_btn hover">{isLoading ? <Loader isWhite/> : "Next"}</div>
            <div onClick={toggleIsEmail} className="auth_type_btn hover">{isEmailAuth ? "Sign in with SMS" : "Sign in with email"}</div>
            {error != "" && <div className="error">{error}</div>}
        </div>
    )
}
export default LogIn;