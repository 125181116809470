import '../../styles/auth.css';
import { useState, useEffect, useCallback } from 'react';
import { Pages } from '../../utils/Utils';
import LogIn from './LogIn';
import back_img from '../../img/menu/back.svg'
import {
    Route
  } from "react-router-dom";
  import { useHistory } from "react-router-dom";

import Footer from '../landing/custom_views/Footer';
import SmsCode from './SmsCode';
function Auth(props) {
    const [isShowRegistration, setShowRegistration] = useState(true)
    const [phoneNumber, setPhoneNumber] = useState("1");
    const [unmaskedPhone, setUnmaskedPhone] = useState("1");
    const history = useHistory()
    const setPhone = (phone, clearPhone) => {
        if (phone == undefined || phone == ""){
            setPhoneNumber("")
            setPhoneNumber("1")
        } else {
            if (phone.length < 3){
                setPhoneNumber("1")
            } else {
                setPhoneNumber(phone)
            }
        }
        setUnmaskedPhone(clearPhone)
    }
    return(
        <div className="main">
            <img onClick={() => {history.goBack()}} className="back_img hover" src={back_img}/>
             {/* <Route path={Pages.sign_up}><Registration phoneNumber={phoneNumber} setPhone={setPhone} setShowRegistration={setShowRegistration}/></Route> */}
                <Route path={Pages.sign_in}><LogIn unmaskedPhone={unmaskedPhone} phoneNumber={phoneNumber} setPhone={setPhone} setShowRegistration={setShowRegistration}/></Route>
                <Route path={Pages.sms_code}><SmsCode /></Route>
            <Footer/>
        </div>
    )
}
export default Auth;