import { useState, useEffect, useCallback } from 'react';
import { MyImage } from '../../../utils/Utils';
import card_img from '../../../img/cards/cards_template.svg'
function Card(props) {
    const card = props.card

    return(
        <div onClick={() => props.setSelectedCard(card)} className={props.selectedCard.cardId == card.cardId ? "cards_card cards_card_border hover" : "cards_card hover"}>
            <MyImage className="cards_card_img" src={card_img}/>
            <div className="cards_card_balance">${card.amount}</div>
        </div>
    )
}
export default Card;