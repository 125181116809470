import React from 'react'
import '../styles/loader.css'
class Loader extends React.Component {
    constructor(props){
        super(props)
        this.whiteStyle = {border : '3px solid white', top : '-2px', borderColor: 'white transparent transparent transparent'}
    }
    
    render(){
            return (
                <div style={this.props.style != undefined ? this.props.style : {}} className="loader_chat_container">
                    <div className="lds-ring loader_container">
                        <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                        <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                        <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                        <div style={this.props.isWhite ? this.whiteStyle : {}}></div>
                        {/* <p className="loader_text">{this.props.text}</p> */}
                    </div>
                </div>
            )   
    }
}
export default Loader
