import { error, log, onResponse, getRequestUrl, getHttpParams, HttpType, checkAuthToken } from "./ApiUtils";
import { getCookie } from "../utils/CookieHelper";
import { CookieConst, logOut } from "../utils/Utils";
export class UsersApi {
    
    static userRequest(params, onSuccess, onError, setLoading) {
        setLoading(true)
        fetch(getRequestUrl() + "/v1/user-request", getHttpParams(params, HttpType.POST)).then((response) => response.json())
        .then((responseData) => {
            log("userRequest response = ", responseData)
            checkAuthToken(responseData, onSuccess, onError, setLoading)
        })
    }
   
   
}