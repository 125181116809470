import React, { useState, useEffect } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { getIsMobile, openApp } from '../../../utils/Utils';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { getHolidays, getNextHoliday } from '../utils/HolidaysUtils';


function Holidays(props) {
    const [slides, setSlides] = useState([
            getNextHoliday().holiday,
            {id : 1, text : "Birthday", img : "🐣"},
            {id : 2, text : "Graduation", img : "🎓"},
            {id : 3, text : "Just Because", img : "🎉"},
            {id : 4, text : "Love", img : "🧡"},
            {id : 5, text : "Thank You", img : "🙏"},
        ]
    )
    
    const getInnerCorousel = () => {
        return(
            <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                {slides.map((item, index) => (
                    <Tag item={item} itemId={item.id} index={index} key={index}/>
                ))}
            </ScrollMenu>
        )
    }
    
    console.log("getHolidays = ", getHolidays())
    console.log("getNextHoliday = ", getNextHoliday())
    return(
        <div className="holidays">
            <div className="holidays_tags">
                {getInnerCorousel()}
            </div>
            <div className="holidays_gradient">
                <svg xmlns="http://www.w3.org/2000/svg" width="43" height="38" viewBox="0 0 43 38" fill="none">
                    <rect x="43" width="38" height="43" transform="rotate(90 43 0)" fill="url(#paint0_linear_1259_18112)"/>
                    <defs>
                        <linearGradient id="paint0_linear_1259_18112" x1="62" y1="0" x2="62" y2="43" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#9E9EFF"/>
                        <stop offset="1" stopColor="#9E9EFF" stopOpacity="0"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div onClick={() => openApp()} className="holidays_download holidays_download_mob hover">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_1259_18107)">
                        <path d="M8 12L12 16M12 16L16 12M12 16V8M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1259_18107">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <span className="holidays_download_text">Download app*</span>
            </div>
        </div>
    )
}

function Tag({ item, itemId, index }){
    const visibility = React.useContext(VisibilityContext);
    const visible = visibility.isItemVisible(itemId);
    const isMobile = getIsMobile()
    const isToday = item.days_to == 0
    return (
        <div style={isToday ? {background: '#FDFF8D', color : 'black', marginLeft : index == 0 ? '16px' : '0'} : {marginLeft : index == 0 ? '16px' : '0'}} className="holidays_tag">
            <div style={{marginRight : "10px"}}>{item.img}</div> 
            {(!isToday && item.days_to != undefined) && <span style={{color: '#FDFF8D', marginRight : '10px'}}>{item.days_to_text}</span>}
            {((isToday || item.days_to == undefined) ? "" : "to ") + item.text}
        </div>
    );
}
function LeftArrow() {
    const { isFirstItemVisible, scrollPrev,items,
        scrollToItem,
        getItemElementById } = React.useContext(VisibilityContext);
    return (
        <div onClick={() => {
            if (isFirstItemVisible) {
              scrollToItem(getItemElementById(items.toArr().slice(-1)?.[0]?.[0]));
            } else {
              scrollPrev();
            }
          }} className="holidays_arrow hover">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                <path d="M9 17L1 9L9 0.999999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    ); 
}
  
function RightArrow() {
    const { isLastItemVisible, scrollNext, items,
        scrollToItem,
        getItemElementById } = React.useContext(VisibilityContext);
    return (
        <div onClick={() => {
            if (isLastItemVisible) {
              scrollToItem(getItemElementById(items.toArr()?.[0]?.[0]));
            } else {
              scrollNext();
            }
          }} className="holidays_arrow hover">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                <path d="M1 17L9 9L1 0.999999" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
}


export default Holidays;

