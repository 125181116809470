import '../../styles/auth.css';
import { useState, useRef, useEffect } from 'react';
import Loader from '../../custom_views/Loader';
import { getUrlParameter, CookieConst, Pages } from '../../utils/Utils';
import CodeTimer from './custom_views/CodeTimer';
import { useHistory } from "react-router-dom";
import { AuthApi } from '../../api/AuthApi';
import { setCookie } from '../../utils/CookieHelper';

function SmsCode(props) {
    const inputRef = useRef(null)
    const [isLoading, setLoading] = useState(false)
    const [code, setCode] = useState("");
    const [code_1, setCode_1] = useState("");
    const [code_2, setCode_2] = useState("");
    const [code_3, setCode_3] = useState("");
    const [code_4, setCode_4] = useState("");
    const [isCodeError, setCodeError] = useState("");
    const [timerFinished, setTimerFinished] = useState(false);
    const history = useHistory()
    const toggleCodeError = () => { setCodeError(current => !current)}
    const inputSelectedStyle = { border: '2px solid var(--green)' }
    const clearContact = getUrlParameter("clear_contact")
    useEffect(() => {
        inputRef.current.focus();
    }, [])
    const codeEntered = (text) => { 
        const enteredCode = text
        if (enteredCode.length < 5){
            setCode(enteredCode)
            switch (enteredCode.length) {
                case 0:
                    setCode_1("")
                    break;
                    case 1:
                        setCode_1(enteredCode[0])
                        setCode_2("")
                        break;
                    case 2:
                        setCode_2(enteredCode[1])
                        setCode_3("")
                        break;
                    case 3:
                        setCode_3(enteredCode[2])
                        setCode_4("")
                        break;
                    case 4:
                        setCode_4(enteredCode[3])
                        confirmCode(text)
                        break;
                default:
                    break;
            }
        }
    }
    var isCodeEntered = code.length >= 4
    const confirmCode = (text) => {
        setCodeError("")
        const params = {contact : getUrlParameter("contact"), confirmationCode : text}
        AuthApi.contactConfirmation(params, (responseData) => {
           console.log("contactConfirmation token = ", responseData.token)
           if (responseData.token){
               setCookie(CookieConst.token, responseData.token)
               setCookie(CookieConst.clear_contact, clearContact)
               setCookie(CookieConst.contact, params.contact)
               history.push(Pages.cards)
           }
        }, (responseData) => {
            setCodeError("Invalid code")
            setTimeout(function() {
                clearCode()
                
              }, 1500)
        }, setLoading)
    }
    const sendAgain = () => {
        setTimerFinished(true)
        const params = {userContact : getUrlParameter("contact")}
        AuthApi.passwordRecovery(params, (responseData) => {
            setTimerFinished(false)
        }, (responseData) => {
        }, setLoading)
    }
    const clearCode = () => {
        setCodeError("")
        codeEntered("")
        setCode_1("")
        setCode_2("")
        setCode_3("")
        setCode_4("")
    }
    return(
        <div className="auth_form_bg">
            <div className="auth_title">Enter Code</div>
            <div className="auth_desc">Code sent to<br/><b>{clearContact}</b><br/></div>
            <div className="code_input_container">
                <div className="code_inputs">
                    <input style={code_1 == "" ? inputSelectedStyle : {}} className="code_input" value={code_1} onChange={(e) => setCode_1(e.target.value)}/>
                    <input style={(code_1 != "" && code_2 == "") ? inputSelectedStyle : {}} className="code_input"  value={code_2} onChange={(e) => setCode_2(e.target.value)}/>
                    <input style={(code_2 != "" && code_3 == "") ? inputSelectedStyle : {}} className="code_input" value={code_3} onChange={(e) => setCode_3(e.target.value)}/>
                    <input style={(code_3 != "" && code_4 == "") ? inputSelectedStyle : {}} className="code_input" value={code_4} onChange={(e) => setCode_4(e.target.value)}/>
                </div>
                <input ref={inputRef} className="hidden_code_input" type="tel" value={code} onChange={(e) => codeEntered(e.target.value)}/>
            </div>
            {isLoading ? <div className="sms_loader_container"><Loader isWhite/></div> :
            <div>
                {isCodeError ? <div className="error">{isCodeError}</div>
                :
                <div>
                    {timerFinished ? <p onClick={sendAgain} className="code_timer hover">Resend the code</p> :
                    <CodeTimer setTimerFinished={setTimerFinished}/>}
                </div>}
                
            </div>}
            {/* <button onClick={confirmCode} style={!isCodeEntered ? {background: 'rgba(255, 255, 255, 0.5)', boxShadow : 'none', pointerEvents : 'none'} : {}} className="auth_next_btn hover">{isLoading ? <Loader isWhite/> : "Next"}</button> */}

        </div>
    )
}
export default SmsCode;