import { useState, useEffect, useCallback } from 'react';
import { MyImage, openApp } from '../../../utils/Utils';
import { CardsApi } from '../../../api/CardsApi';
import Loader from '../../../custom_views/Loader';
import moment from 'moment'

function TransactionsHistory(props) {
    const [isLoading, setLoading] = useState(true)
    const [transactions, setTransactions] = useState([])
    const card = props.card
    useEffect(() => {
        CardsApi.getTransactions(card.cardId, (responseData) => {
            setTransactions(addDateTitles(responseData)) 
        }, (error) => {
 
        }, setLoading)
     }, [card])

    const addDateTitles = (jsonArray) => {
        var newArray = [...jsonArray]
        var prevDate = ""
        for (let i = 0; i < jsonArray.length; i++) {
            const item = jsonArray[i];
            let date = parseDate(item.date)
            if (prevDate != date){
                prevDate = date
                newArray[i].dateTitle = date
            }
        }
        return newArray
        // return [
        //     {dateTitle : "May 31", amount : 5, merchantName : "Starbacks"},
        //     {amount : -5, merchantName : "Starbacks"},
        //     {amount : -3, merchantName : "Starbacks"},
        //     {amount : -2, merchantName : "Starbacks"},
        //     {dateTitle : "June 1", amount : -3, merchantName : "Starbacks"},
        //     {dateTitle : "June 3", amount : -10, merchantName : "Starbacks"},
        //     {dateTitle : "June 5", amount : 0, merchantName : "McDonalds"},
        //     {dateTitle : "May 31", amount : 5, merchantName : "Starbacks"},
        //     {amount : -5, merchantName : "Starbacks"},
        //     {amount : -3, merchantName : "Starbacks"},
        //     {amount : -2, merchantName : "Starbacks"},
        //     {dateTitle : "June 1", amount : -3, merchantName : "Starbacks"},
        //     {dateTitle : "June 3", amount : -10, merchantName : "Starbacks"},
        //     {dateTitle : "June 5", amount : 0, merchantName : "McDonalds"},
        // ]
    }
    const parseDate = (dateStr) => {
        console.log("dateStr = " + dateStr)
        return moment(dateStr,  dateStr.includes("+") ? "YYYY-MM-DD[T]HH:mm:ss +hhmm" : "YYYY-MM-DD[T]HH:mm:ss -hhmm").format("MMM DD")
    }
    return(
        <div style={{padding : '0', maxHeight : '390px'}} className="cards_transactions cards_list_container">
            {!isLoading && <div>
                <div className="cards_empty_transactions">
                    {transactions.length == 0 ? "No transactions yet" : "Recent transactions:"}
                </div> 
            
                <div>
                    {transactions.length > 0 && transactions.map((item, index) => (
                        <Transaction transaction={item} index={index} key={index}/>
                    ))}
                </div>
                <div onClick={() => openApp()} className="cards_transactions_more hover">View more in app</div>
            </div>}
        </div>
    )
}
export default TransactionsHistory;

const Transaction = ({ transaction }) => {
    
    return (
        <div className="cards_transactions_item">
            {transaction.dateTitle && <div className="cards_transactions_date">{transaction.dateTitle}</div>}
            <div className="cards_transactions_row">
                <div className="cards_transactions_merchant">{transaction.merchantName}</div>
                {transaction.amount != null && <div style={{textAlign : 'right'}} className="cards_transactions_merchant">{transaction.amount > 0 ? "+$" : "-$"}{String(transaction.amount).replaceAll("-", "")}</div>}
            </div>
        </div>
    )
}