import '../../styles/landing.css';
import { useState, useEffect, useCallback } from 'react';
import HowItWorks from './custom_views/HowItWorks';
import Faq from './custom_views/Faq';
import DownloadApp from './custom_views/DownloadApp';
import NeedHelp from './custom_views/NeedHelp';
import Footer from './custom_views/Footer';
import landing_pic_1 from '../../img/landing/landing_pic_1.svg'
import landing_pic_1_mob from '../../img/landing/landing_pic_1_mob.svg'
import { getIsMobile } from '../../utils/Utils';
import Holidays from './custom_views/Holidays';

function Landing(props) {
    const isMobile = getIsMobile()
    return(
        <div className="main">
            <img className="landing_pic_1"/>
            <h1 className="landing-title">Order a Congratz<br/>Mastercard®<br/>Gift Card</h1>
            <Holidays />
            <HowItWorks/>
            <Faq />
            <DownloadApp />
            <NeedHelp />
            <Footer/>
        </div>
    )
}
export default Landing;