import { error, log, onResponse, getRequestUrl, getHttpParams } from "./ApiUtils";
export class AuthApi {
    
    static passwordRecovery(params, onSuccess, onError, setLoading) {
        setLoading(true)
        fetch(getRequestUrl() + "v2/password-recovery-code", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("passwordRecovery response = ", responseData)
            onResponse(responseData, onSuccess, onError, setLoading)
        })
    }
    static contactConfirmation(params, onSuccess, onError, setLoading) {
        setLoading(true)
        fetch(getRequestUrl() + "v1/contact-confirmation", getHttpParams(params))
        .then(function(response) {
            console.log("response.result = ", response.body); // Will show you the status
            if (!response.ok) {
                return response.json()
            }
            
           return response;
        })
        // .then((response) => response.json())
        .then((responseData) => {
            if (responseData.errorDescription != undefined){
                onResponse(responseData, onSuccess, onError, setLoading)
            } else {
                responseData.text().then(function (text) {
                    onResponse({token : text}, onSuccess, onError, setLoading)
                });
            }
        })
    }
   
   
}