import faq_card from '../../../img/landing/faq_card.svg'
import faq_girl from '../../../img/landing/faq_girl.svg'
import faq_bg from '../../../img/landing/faq_bg.png'
import arrow_down from '../../../img/landing/arrow_down.svg'
import arrow_up from '../../../img/landing/arrow_up.svg'
import more_arrow from '../../../img/landing/more_arrow.svg'
import { useState, useEffect, useCallback } from 'react';
import { Pages, openApp } from '../../../utils/Utils';

function Faq(props) {
    const [isShowMore, setShowMore] = useState(false)
    //[url] заменить на линк в стор
    const appUrl = Pages.ios_app_url
    const questions = [
        {question : 'What is a Congratz Mastercard® Gift Card?', answer : 'A Congratz Mastercard Gift Card is a prepaid virtual gift card.'},
        {question : 'How can I gift a Congratz Mastercard® Gift Card?', answer : 'It’s simple! Download the <a href=' + appUrl + ' target="_blank" class="hover download_app_a_tag">Congratz app*</a> and follow the instructions. A Congratz Mastercard® Mastercard Gift Card is issued in real time.<br/><br/>You choose:', list : ["card denomination.", "and a sweet message to complete your gift.", "and a sweet message to complete your gift."], list_desc : "*Standard data rates from your wireless service provider may apply."},
        {question : 'How much does a Congratz Mastercard® Gift Card cost?', answer : 'There is a one-time purchase fee at the time the gift card is purchased.', list : ["Gift card denomination $20.00 = $0.99", "Gift card denomination $50.00 = $1.99", "Gift card denomination $100.00 = $2.99", "Gift card denomination $500.00 = $3.99"]},
        {question : 'How can Congratz Mastercard® Gift Cards be used? ', answer : 'The Congratz Mastercard® Gift Card can be redeemed in-store where Apple Pay®, Google PayTM, and Samsung Pay® are accepted and at every internet, mail order and telephone merchant where Debit Mastercard® is accepted in the United States and District of Columbia. No cash or ATM access. Card funds never expire.'},
        {question : 'Can I gift a Congratz Mastercard®  Gift Card to anyone I want to?', answer : 'Sure! As long as they have a U.S. mobile number and are over the age of 18.'},
        //more btn
        {question : 'How fast will Congratz Mastercard® Gift Card be delivered?', answer : 'Congratz Mastercard® Gift Cards are normally delivered within 5† minutes. In rare circumstances, your delivery may be delayed. You can check the status of your gift card on the Your Orders page.', list_desc : "†Most approved Gift Card orders are delivered within five (5) minutes but can take up to 24 hours."},
        {question : 'How will I know that my Congratz Mastercard® Gift Card order has been delivered?', answer : 'You will get an email / push notification when the recipient receives the Congratz Mastercard® Gift Card. You also can confirm delivery by checking the order status on the Your Orders page (the status will be changed to ‘received’).'},
        {question : 'I’ve just received a Congratz Mastercard®Mastercard Gift Card, how do I activate it?', answer : 'Congratz on receiving a Congratz Mastercard® Gift Card! Your special gift is only a few clicks away: just follow the link in the message you received or download the <a href=' + appUrl + ' target="_blank" class="hover download_app_a_tag">Congratz app*</a>'},
        {question : 'How do I check the balance on my card?', answer : 'You may check your card balance at any time by using the <a href=' + appUrl + ' target="_blank" class="hover download_app_a_tag">Congratz mobile app</a> customer service at 1-844-504-1504'},
        {question : 'Have any questions left?', answer : 'Log into the Congratz mobile app and tap the Help & Support icon or call Customer Service at: 1-844-504-1504 (Toll Free within the USA).', list_desc : '*Standard data rates from your wireless service provider may apply.'},
        {question : 'What if I put in the wrong email address or phone number?', answer : 'Please contact our support team at support@congratz.io.'},
        {question : 'Can I use a virtual Congratz Mastercard® Gift Card at an ATM?', answer : 'No, the Congratz Mastercard® Gift Card has no cash or ATM access.'},
        {question : 'How do I report my card lost or stolen?', answer : 'To report your card lost or stolen, call Customer Service at 1-844-504-1504.'},
        {question : 'Am I liable for unauthorized transactions?', answer : 'You must notify us immediately at 1-844-504-1504 of any unauthorized use. See the <a href="' + Pages.cardholder_agreement + '" target="_blank" class="landing-url hover">Cardholder Agreement</a> for complete details.'},
        {question : 'Can I use a Congratz Mastercard®Mastercard Gift Card in New Zealand?', answer : 'No, Congratz Mastercard® Gift Cards can only be used in the United States They cannot be used at merchants outside the United States, including internet, mail order or telephone merchants outside the U.S.'},
        
    ]
    return(
        <div className="faq relative">
            <div className="faq_title">
                <span><h2>You are all set!</h2></span>
                <h2>You are all set!</h2>
            </div>
            <div className="faq_bg">
                {/* <img className="faq_bg_image" src={faq_bg}/> */}
                <div className="faq_text">
                    Use the card:
                    <ul>
                        <li>anywhere Debit Mastercard is accepted in the United States and District of Columbia</li>
                        <li>online</li>
                        <li>at restaurants, cafes & stores where Apple Pay®, Google Pay™, and Samsung Pay® are accepted</li>
                    </ul>
                </div>
                
                <img className="faq_card" src={faq_card}/>
                <img className="faq_girl" src={faq_girl}/>
            </div>

            <div className="faq-questions">
                <div className="faq-questions_title">FAQ</div>
                {questions.length > 0 && questions.map((item, index) => (
                    <div index={index} key={index}>
                        {(isShowMore || (!isShowMore && index <=5)) && <Question item={item} isLast={index == questions.length - 1} index={index} key={index}/>}
                    </div>
                ))}
                {!isShowMore && <div onClick={() => setShowMore(!isShowMore)} className="faq-questions_more hover">
                    More
                    <img className="faq-questions_more_icon" src={more_arrow}/>
                </div>}
            </div>
        </div>
    )
}
export default Faq;

const Question = ({ item, isLast, index }) => {
    const [isSelected, setSelected] = useState(index == 0)
    return (
        <div style={isLast ? {border : 'none'} : {}} className="faq-questions_item_cont">
            <div className="flex" >
                <img onClick={() => setSelected(!isSelected)} className="faq-questions_arrow_cont hover" src={isSelected ? arrow_up : arrow_down}/>
                <div className="faq-questions_item">
                    <div dangerouslySetInnerHTML={{__html: item.question}}></div>
                    <div className="faq-questions_item_answer_cont" style={isSelected ? {maxHeight: '500px', marginBottom : '24px'} : {maxHeight: '0px', opacity : 0}}>
                        <div className="faq-questions_item_answer" dangerouslySetInnerHTML={{__html: item.answer}}></div>
                        <div style={{marginTop : '12px'}} className="faq-questions_item_answer">
                            {(item.list && item.list.length > 0) && item.list.map((item, index) => (
                                <li index={index} key={index}>{item}</li>
                            ))}
                            {item.list_desc && <div className="faq-questions_item_desc">{item.list_desc}</div>}
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
    )
}