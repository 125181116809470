import footer_logo from '../../../img/landing/footer_logo.svg'
import email_icon from '../../../img/landing/email.png'
import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Pages } from '../../../utils/Utils';
import FooterScrollLink from './FooterScrollLinks';
function Footer(props) {
   
    return(
        <footer className="footer">
            <div className="footer_logo_cont">
                <img className="footer_logo" src={footer_logo}/>
                <div className="footer_column_cont">
                    <div className="footer_column_1">
                        <Link to={Pages.sign_in}><div className="footer_link footer_link_sign_in hover">Sign in</div></Link>
                        <div className="footer_link hover">
                            <FooterScrollLink title="FAQ" to="faq-questions" className="footer_link hover"/>
                        </div>
                        <div className="footer_link hover">
                            <FooterScrollLink title="Contact us" to="need-help" className="footer_link hover"/>
                        </div>
                    </div>
                    <div className="footer_column_2">
                        <a href={Pages.terms_of_use} target="_blank"><div className="footer_link hover">Terms of Use</div></a>
                        <a href={Pages.cardholder_agreement} target="_blank"><div className="footer_link hover">Cardholder Agreement</div></a>
                        <a href={Pages.privacy_policy} target="_blank"><div className="footer_link hover">Privacy Policy</div></a>
                    </div>
                    {/* <div className="footer_email_icon hover">
                        
                            
                    </div>
                    <Link offset={-150} smooth={"true"} to="need-help" className="footer_email_icon hover">
                        <img className="footer_email_icon hover" src={email_icon}/>
                    </Link> */}
                    <a href="mailto:support@congratz.io"><img className="footer_email_icon hover" src={email_icon}/></a>
                </div>
            </div>
           <div>
               <div className="footer_contants_info">Aart, Inc.<br/>Congratz Mastercard® Gift Card.<br/>Phone: 1 (844) 504-1504 (Toll Free within the USA) 24/7<br/>Address: Congratz Mastercard Gift Card, PO Box 124, Dell Rapids, SD 57022</div>
               <div className="footer_congratz">© Aart, Inc. 2022-2023</div>
               <div className="footer_trademarks">*Standard data rates from your wireless service provider may apply.<br/>†Most approved Gift Card orders are delivered within five (5) minutes but can take up to 24 hours.</div>
               <div className="footer_trademarks">The Congratz.io is not responsible for unauthorized use.<br/>The Congratz Mastercard® Gift Card is issued by Central Bank of Kansas City, Member FDIC, pursuant to a license from Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. Cards can be redeemed in-store where Apple Pay®, Google Pay™, and Samsung Pay® are accepted and at every internet, mail order and telephone merchant where Debit Mastercard is accepted in the United States and District of Columbia. No cash or ATM access. Terms and conditions apply. Consult your Cardholder Agreement for complete details.</div>
           </div>
        </footer>
    )
}
export default Footer;