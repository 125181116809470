import need_help_bg from '../../../img/landing/need_help_bg.png'
import need_help_title_icon from '../../../img/landing/need_help_title_icon.svg'
import { useState, useRef, useEffect } from 'react';
import { Pages } from '../../../utils/Utils';
import landing_pic_2  from '../../../img/landing/landing_pic_2.svg'
import autosize from 'autosize';
import { UsersApi } from '../../../api/UsersApi';
function NeedHelp(props) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [text, setText] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [toastText, setToastText] = useState("")
    const textRef = useRef(null)
    useEffect(() => {
        autosize(textRef.current)
    }, [])
    const userRequest = () => {
        var params = {userName : name, userEmail : email, text : text}
        UsersApi.userRequest(params, (response) => {
            showToast("Thank you for contacting us!<br/>We'll get back to you very soon.")
            clearText()
        }, (error) => {

        }, setLoading)
        // setLoading(true)
        // showToast("Thank you for contacting us!<br/>We'll get back to you very soon.")
        // clearText()
    }
    const clearText = () => {
        setEmail("")
        setName("")
        setText("")
    }
    const showToast = (text) => {
        setToastText(text)
        setTimeout(function() {
            setToastText("")
        }, 2000)
    }
    const isBtnEnabled = email.includes("@") && email.includes(".") && name.length > 1 && text.length > 0
    return(
        <div className="relative">
             
            <div className="need-help relative">
                {/* <img className="need-help_bg_image" src={need_help_bg}/> */}
                
                <div>
                    <div className="need-help_title_cont">
                        <div className="need-help_title">Need help? Contact us</div>
                        <img className="need-help_title_icon" src={need_help_title_icon}/>
                    </div>
                    
                    <InputRow placeholder="Your name" type="text" value={name} setValue={setName}/>
                    <InputRow placeholder="Your email address" type="email" value={email} setValue={setEmail}/>
                    {/* <InputRow placeholder="Describe your problem" type="text" value={text} setValue={setText}/> */}
                    
                    <div>
                        <textarea style={{paddingBottom : '0'}} ref={textRef} placeholder="Describe your problem" className="need-help_input"  value={text} onChange={(e) => setText(e.target.value)}/>
                        <div className="fill_navbar_line"/>
                    </div>
                </div>
                <button onClick={userRequest} style={isBtnEnabled ? {} : {pointerEvents : 'none', opacity : '0.2'}} className="need-help_btn hover">Get in touch</button>
                <div className="need-help_privacy">You agree with <a href={Pages.privacy_policy} target="_blank" className="hover">Privacy Policy</a></div>
                {toastText != "" && <div className="need-help_toast" dangerouslySetInnerHTML={{__html: toastText}}></div>}

            </div>
            <img className="landing_pic_girl" src={landing_pic_2}/>
        </div>
    )
}
export default NeedHelp;


const InputRow = ({ placeholder, type, value, setValue }) => {
   
    return (
        <div>
            <input className="need-help_input" placeholder={placeholder} type={type} value={value} onChange={(e) => setValue(e.target.value)}/>
            <div className="fill_navbar_line"/>
        </div>
    );
}

