import BaseDesktopDialog from "../../base/BaseDesktopDialog";
import { useState } from 'react';
import { CardsApi } from "../../../api/CardsApi";
import Loader from "../../../custom_views/Loader";

function LockDialog(props) {
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const cancel = () => {
        props.setShow(false)
    }
    const ok = () => {
        setError("")
        CardsApi.blockCard(props.card.cardId, (responseData) => {
            props.onCardLocked(props.card)
            props.setShow(false)
        }, (error) => {
            setError(error.errorDescription)
            // props.onCardLocked(props.card)
            // props.setShow(false)
        }, setLoading)
        
    }
    const getInnerHtml = () => {
        return(
            <div>
                <div className="dialog_title">Are you sure want to lock the card?</div>
                {error != "" && <div className="dialog_error">{error}</div>}
                <div className="dialog_buttons cards_dialog_buttons">
                    <div onClick={cancel} className="dialog_cancel hover">Cancel</div>
                    <div onClick={ok} className="dialog_ok hover">{isLoading ? <Loader isWhite/> : "Lock"}</div>
                </div>
            </div>
        )
    }
    if (!props.isShow){
        return(<div/>)
    } 
    return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    
}

export default LockDialog;
