import { useState, useEffect, useCallback } from 'react';
import SelectedCard from '../custom_views/SelectedCard';
import Card from '../custom_views/Card';
import { CardsApi } from '../../../api/CardsApi';
import EmptyCards from '../custom_views/EmptyCards';


function SentCards(props) {
    const [isLoading, setLoading] = useState(false)

    const [cards, setCards] = useState([])
    const [selectedCard, setSelectedCard] = useState({})
    useEffect(() => {
        CardsApi.getSentCards((responseData) => {
            setCards(responseData)
            if (responseData.length > 0){
                setSelectedCard(responseData[0])
            }
        }, (error) => {
 
        }, setLoading)
     }, [])
    return(
        <div className="cards_my_container">
             {!isLoading && cards.length == 0 ? 
                <EmptyCards />
            :
            <div className="flex">
                <div className="cards_list_container">
                    {cards.length > 0 && cards.map((card, index) => (
                        <Card selectedCard={selectedCard} setSelectedCard={setSelectedCard} key={index} card={card} index={index}/>
                    ))}
                </div>
                <div className="cards_list_line"/>
            </div>}
            {selectedCard.cardId != undefined && <SelectedCard card={selectedCard}/>}
        </div>
    )
}
export default SentCards;