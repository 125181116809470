import phone from '../../../img/landing/phone.png'
import info_white from '../../../img/landing/info_white.svg'
import app_store from '../../../img/landing/app_store.png'
import google_play from '../../../img/landing/google_play.png'
import { openApp, openAndroidApp } from '../../../utils/Utils';

function DownloadApp(props) {
   
    return(
        <div className="download_app">
            <img className="download_app_phone" src={phone}/>
            <div style={{width : '100%'}}>
                <div className="download_app_title">Download our app*<br/>and make your friends happy!</div>
                <div className="download_app_desc">Your perfect gift for all occasions.</div>
                <div className="download_app_flex">
                    <img className="download_app_info_icon" src={info_white}/>
                    <div className="download_app_info_text">Purchase fee applies to gift card orders.</div>
                </div>
                <div className="download_app_flex">
                    <img onClick={() => openApp()} className="download_app_store hover" src={app_store}/>
                    <img onClick={() => openAndroidApp()} className="download_app_google hover" src={google_play}/>
                </div>
                <div className="download_app_trademark">Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries.<br/>App Store is a service mark of Apple Inc., registered in the U.S. and other countries.<br/>Google Play and the Google Play logo are trademarks of Google LLC.</div>
                <img className="download_app_phone_m" src={phone}/>
            </div>
            
        </div>
    )
}
export default DownloadApp;