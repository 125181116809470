import { useState, useEffect, useCallback } from 'react';
import { MyImage, openAgreement, CardStatus } from '../../../utils/Utils';
import card_img from '../../../img/cards/big_card_template.svg'
import lock_card from '../../../img/cards/lock_card.svg'
import { CardsApi } from '../../../api/CardsApi';
import Loader from '../../../custom_views/Loader';
import TransactionsHistory from './TransactionsHistory';
import LockDialog from '../dialogs/LockDialog';

function SelectedCard(props) {
    const [isLoading, setLoading] = useState(false)
    const [cardBalance, setCardBalance] = useState(-1)
    const [isShowLockDialog, setShowLockDialog] = useState(false)
    const card = props.card
    const isCardLocked = props.card.status == CardStatus.BLOCKED
    const isMyCards = props.onCardLocked != undefined
    useEffect(() => {
        if (isMyCards){
            CardsApi.getBalance(card.cardId, (responseData) => {
                setCardBalance(responseData.balance) 
            }, (error) => {
    
            }, setLoading)
        }
    }, [card])
    const lockCard = () => {
        setShowLockDialog(!isShowLockDialog)
    }
    const showAgreement = () => {
        openAgreement()
    }
    console.log("isCardLocked", isCardLocked)
    return(
        <div className="cards_selected_card_cont">
            <div className="relative">
                <div className="relative">
                    <MyImage className="cards_selected_card" src={card_img}/>
                    <div className="cards_card_selected_balance">${card.amount}</div>
                    <div className="cards_selected_last_digits">*{card.lastFour}</div>
                    {(isCardLocked && isMyCards) && <CardProblem title="Card is locked"/>}

                    {(!isMyCards && (card.status == CardStatus.DRAFT || card.status == CardStatus.NEW_NO_MONEY)) && <CardProblem title="Payment failed"/>}
                    {(!isMyCards && card.status == CardStatus.TRANSFERED) && <CardProblem title="The card is pending"/>}
                </div>
                {isMyCards && 
                    <div>
                        {isCardLocked ? 
                            <div className="cards_lock_title cards_locked_phone center_horizontal">To unlock the card please call<br/>1-844-504-1504</div>
                        :
                        <div onClick={lockCard} className="hover">
                            <MyImage className="cards_lock_card center_horizontal" src={lock_card}/>
                            <div className="cards_lock_title center_horizontal">Lock</div>
                        </div>}
                    </div>
                }
                <div onClick={showAgreement} className="cards_funds hover">Card funds never expire<br/><b>Congratz's Cardholder Agreement</b></div>
            </div>
            {isMyCards && <div style={{width : '100%'}}>
                <div className="cards_selected_balance">Balance:</div>
                <div className="cards_selected_balance_info relative">{(isLoading || cardBalance == -1) ? <Loader style={{margin : '24px 0 0 -32px'}}/> : "$" + cardBalance}</div>
                <div className="cards_selected_line"/>
                <TransactionsHistory card={card}/>
            </div>}
            <LockDialog card={card} isShow={isShowLockDialog} setShow={setShowLockDialog} onCardLocked={props.onCardLocked}/>
        </div>
    )
}
export default SelectedCard;

const CardProblem = ({ title }) => {
    
    return (
        <div className="cards_locked_card">
            <div className="cards_locked_card_title">{title}</div>
            <div className="cards_locked_card_valid">Valid only in the United States</div>
        </div>
    )
}