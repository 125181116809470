import how_it_works_1 from '../../../img/landing/how_it_works_1.svg'
import how_it_works_2 from '../../../img/landing/how_it_works_2.svg'
import how_it_works_3 from '../../../img/landing/how_it_works_3.svg'
import how_it_works_4 from '../../../img/landing/how_it_works_4.svg'
import arrow from '../../../img/landing/arrow.svg'
import info from '../../../img/landing/info.svg'
import bottom_arrow from '../../../img/landing/bottom_arrow.svg'
import { openApp } from '../../../utils/Utils';
function HowItWorks(props) {
   
    return(
        <div id="how-it-works" className="how-it-works">
            <div className="how-it-works_title">How it <span>works</span></div>
            <div className="how-it-works_desc">It’s simple! Download the  <span onClick={() => openApp()} className="hover">Congratz app*</span> at App Store and follow the instructions</div>
            <div className="how-it-works_images">
                    <div className="how-it-works_item">
                        <img className="how-it-works_item_icon" src={how_it_works_1}/>
                        <div>Download the app*</div>
                    </div>

                    <img className="how-it-works_arrow" src={arrow}/>

                    <div className="how-it-works_item">
                        <img className="how-it-works_item_icon how-it-works_item_icon_2" src={how_it_works_2}/>
                        <div>You choose:<br/>card denomination<br/>and a sweet message<br/>to complete your gift</div>
                        <div className="how-it-works_item_info">
                            <img className="how-it-works_item_info_icon" src={info}/>
                            <p className="how-it-works_item_info_text">Purchase fee applies</p>
                        </div>
                    </div>

                    <img className="how-it-works_arrow" src={arrow}/>

                    <div className="how-it-works_item">
                        <img className="how-it-works_item_icon how-it-works_item_icon_3" src={how_it_works_3}/>
                        <div>Send your gift<br/><br/>A Congratz<br/>Mastercard® Gift Card<br/>is delivered in real<br/>time.† </div>
                    </div>

                    <img className="how-it-works_arrow" src={arrow}/>

                    <div className="how-it-works_item">
                        <img className="how-it-works_item_icon" src={how_it_works_4}/>
                        <div>Let your loved ones<br/>enjoy</div>
                    </div>
            </div>
            <img className="how-it-works_bottom_arrow" src={bottom_arrow}/>
        </div>
    )
}
export default HowItWorks;