import { useState, useEffect, useCallback } from 'react';
import { CardsApi } from '../../../api/CardsApi';
import Card from '../custom_views/Card';
import SelectedCard from '../custom_views/SelectedCard';
import { CardStatus } from '../../../utils/Utils';
import EmptyCards from '../custom_views/EmptyCards';


function MyCards(props) {
    const [isLoading, setLoading] = useState(false)
    const [cards, setCards] = useState([])
    const [selectedCard, setSelectedCard] = useState({})
    useEffect(() => {
       CardsApi.getMyCards((responseData) => {
            setCards(responseData)
            if (responseData.length > 0){
                setSelectedCard(responseData[0])
                //onCardLocked(responseData[0])
            }
       }, (error) => {

       }, setLoading)
    }, [])
    const onCardLocked = (lockedCard) => {
        var card = {...lockedCard}
        card.status = CardStatus.BLOCKED
        setSelectedCard(card)
        var currentCards = [...cards]
        currentCards.forEach(item => {
            if (item.cardId == lockedCard.cardId){
                item.status = CardStatus.BLOCKED
            }
        })
        setCards(currentCards)
    }
    return(
        <div className="cards_my_container">
            {!isLoading && cards.length == 0 ? 
                <EmptyCards />
            :
                <div className="flex">
                    <div className="cards_list_container">
                        {cards.length > 0 && cards.map((card, index) => (
                            <Card selectedCard={selectedCard} setSelectedCard={setSelectedCard} key={index} card={card} index={index}/>
                        ))}
                    </div>
                    <div className="cards_list_line"/>
                </div>
            }
            {selectedCard.cardId != undefined && <SelectedCard onCardLocked={onCardLocked} card={selectedCard}/>}

        </div>
    )
}
export default MyCards;