import { useState, useEffect, useCallback } from 'react';
import { MyImage } from '../../../utils/Utils';
import empty_cards from '../../../img/cards/empty_cards.svg'
function EmptyCards(props) {
    return(
        <div style={{width : '100%'}}>
            <img className="cards_empty_img center_horizontal" src={empty_cards}/>
            <div className="cards_empty_title">No cards</div>
        </div>
    )
}
export default EmptyCards;