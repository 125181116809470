import { error, log, onResponse, getRequestUrl, getHttpParams, HttpType, checkAuthToken } from "./ApiUtils";
import { getCookie } from "../utils/CookieHelper";
import { CookieConst, logOut } from "../utils/Utils";
export class CardsApi {
    
    static getMyCards(onSuccess, onError, setLoading) {
        setLoading(true)
        console.log("getCookie(CookieConst.contact) = ", getCookie(CookieConst.contact))
        fetch(getRequestUrl() + "v1/cards?transferCode=" + getCookie(CookieConst.contact), getHttpParams({}, HttpType.GET)).then((response) => response.json())
        .then((responseData) => {
            log("getMyCards response = ", responseData)
            checkAuthToken(responseData, onSuccess, onError, setLoading)
        })
    }
    static getSentCards(onSuccess, onError, setLoading) {
        setLoading(true)
        fetch(getRequestUrl() + "v1/cards/sent", getHttpParams({}, HttpType.GET)).then((response) => response.json())
        .then((responseData) => {
            log("getSentCards response = ", responseData)
            checkAuthToken(responseData, onSuccess, onError, setLoading)
        })
    }
    static getBalance(card_id, onSuccess, onError, setLoading) {
        setLoading(true)
        fetch(getRequestUrl() + "v1/cards/" + card_id + "/balance", getHttpParams({}, HttpType.GET)).then((response) => response.json())
        .then((responseData) => {
            log("getBalance response = ", responseData)
            checkAuthToken(responseData, onSuccess, onError, setLoading)
        })
    }
    static getTransactions(card_id, onSuccess, onError, setLoading) {
        setLoading(true)
        fetch(getRequestUrl() + "v1/cards/" + card_id + "/transactions", getHttpParams({}, HttpType.GET)).then((response) => response.json())
        .then((responseData) => {
            log("getTransactions response = ", responseData)
            checkAuthToken(responseData, onSuccess, onError, setLoading)
        })
    }
    static blockCard(card_id, onSuccess, onError, setLoading) {
        setLoading(true)
        fetch(getRequestUrl() + "v1/cards/" + card_id + "/blocked", getHttpParams({}, HttpType.PUT)).then((response) => response.json())
        .then((responseData) => {
            log("blockCard asd response = ", responseData)
            checkAuthToken(responseData, onSuccess, onError, setLoading)
        })
    }
   
}