
import { Link, animateScroll as scroller } from "react-scroll";
import * as Scroll from 'react-scroll';
import { getUrlParameter } from "../../../utils/Utils";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
function FooterScrollLink(props) {
    const location = useLocation();
    useEffect(() => {
        const scrollUrl = getUrlParameter("scroll")
        if (scrollUrl != null && scrollUrl != undefined && scrollUrl != ""){
            var element = document.getElementsByClassName(scrollUrl)[0];
                var headerOffset = 150;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
              
                window.scrollTo({
                     top: offsetPosition,
                     behavior: "smooth"
                })
        }
    }, [])
    const isNotLanding = location.pathname != "/" && location.pathname != ""
    
    const onClick = () => {
        if (props.onClick){
            props.onClick()
        }
        window.open('/?scroll=' + props.to, '_blank');
    }
    return(
        <Link onClick={isNotLanding ? onClick : props.onClick} offset={-150} smooth={"true"} to={props.to} className={props.className}>{props.title}</Link>
    )
}
export default FooterScrollLink;