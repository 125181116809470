import { getCookie } from "../utils/CookieHelper";
import { CookieConst, AppState, ConstParams, logOut } from "../utils/Utils";

var isShowLogs = true

export const error = (onError, responseData) => {
    if(onError){
        onError(responseData)
    }
}
export const checkAuthToken = (responseData, onSuccess, onError, setLoading) => {
    if (responseData.status == 401){
        logOut()
    } else {
        onResponse(responseData, onSuccess, onError, setLoading)
    }
}
export const log = (text, object) => {
    if (isShowLogs){
        console.log(text, object)
    }
}
export const HttpType = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT"
}
export const onResponse = (responseData, onSuccess, onError, setLoading) => {
    if (responseData.errorDescription == undefined){
        onSuccess(responseData)
    } else {
        error(onError, responseData)
    }
    setLoading(false)
}

export const getHttpParams = (params, method) => {
    if (method == HttpType.GET){
        return {
            headers: {'Authorization': 'Bearer ' + getCookie(CookieConst.token), "apiKey" : getApiKey(), 'channel' : 'web'}
        }
    }
    var httpParams = {
        method: method == undefined ? HttpType.POST : method,
        headers: {'Content-Type': 'application/json', "apiKey" : getApiKey(), 'channel' : 'web', 'Authorization': 'Bearer ' + getCookie(CookieConst.token)},
        // Authentication: 'Bearer Token', 'X-Custom-Header': getCookie(CookieConst.token),
        body: JSON.stringify(params)
        
    }
    return httpParams;
};

export const getRequestUrl = () => {
    return ConstParams.appState == AppState.test ? "https://api-test.congratz.io/" : "https://api.congratz.io/";
};
export const getApiKey = () => {
    return ConstParams.appState == AppState.test ? "sSVl4jsQ2ST/b7iVyG/Wrg==" : "8YBgbv0Y4J5aw7Yha430pQ=="
};