import moment from 'moment'
import { declension } from '../../../utils/Utils';

export const getCountBetweenDays = (date) =>{
    var given = moment(date, "DD-MM-YYYY");
    var current = moment().startOf('day');
    //var current = moment("03-09-2023", "DD-MM-YYYY");

    //Difference in number of days
    return moment.duration(given.diff(current)).asDays()
}

export const Easter20ops = (year) => {
    /*jslint bitwise: true, vars: true */
    var a = (year / 100 | 0) * 1483 - (year / 400 | 0) * 2225 + 2613;
    var b = ((year % 19 * 3510 + (a / 25 | 0) * 319) / 330 | 0) % 29;
    var c = 148 - b - ((year * 5 / 4 | 0) + a - b) % 7;

    return moment({year: year, month: (c / 31 | 0) - 1, day: c % 31 + 1});
}

export const getHolidayDate = (year, month, week, day) => {
    const firstDay = 1;
    if (week < 0) {
        month++;
    }
    const date = new Date(year, month, (week * 7) + firstDay);
    if (day < date.getDay()) {
        day += 7;
    }
    date.setDate(date.getDate() - date.getDay() + day);
    return moment(date).format("DD-MM-YYYY");
}

export const getParentsDate = (isMother) => {
    var mayFirst = new Date(moment().year() + (isMother ? '-05-01' : '-06-01'));
    var dayOfWeek = mayFirst.getUTCDay();
    var firstSunday = mayFirst;

    if (dayOfWeek != 0) {
        firstSunday.setDate(1 + (7 - dayOfWeek));
    } 
    var mothersDay = new Date(firstSunday);
    mothersDay.setDate(firstSunday.getDate() + (isMother ? 7 : 14));
    mothersDay = new Date(mothersDay);
    return moment.utc(mothersDay).format("DD-MM-YYYY")
}
export const getThanksGivingDate = () => {
    var mayFirst = new Date(moment().year() + '-11-01');
    var dayOfWeek = mayFirst.getUTCDay();
    var firstSunday = mayFirst;
    if (dayOfWeek > 4) {
        firstSunday.setDate(5 + (7 - dayOfWeek));
    } else {
        firstSunday.setDate(1 + (4 - dayOfWeek));
    }
    
    var mothersDay = new Date(firstSunday);
    mothersDay.setDate(firstSunday.getDate() + 21);
    mothersDay = new Date(mothersDay);
    return moment.utc(mothersDay).format("DD-MM-YYYY")
}

export const getNextHoliday = () => {
    const holidays = getHolidays()
    var daysToHoliday = {count : 500, holiday : {}}
    holidays.forEach(element => {
        const diffDays = getCountBetweenDays(element.date)
        if (diffDays >= 0 && daysToHoliday.count >= diffDays){
            daysToHoliday.count = diffDays
            var holiday = element
            holiday.days_to = diffDays
            holiday.days_to_text = diffDays + " " + declension(diffDays, 'day', 'days', 'days')
            daysToHoliday.holiday = holiday
        }
    });
    return daysToHoliday
}

export const getHolidays = () => {
    return [
        {id : 0, text : "New Year’s Eve", img : "🎄", date : "31-12-" + moment().year()},
        {id : 0, text : "New Year's Day", img : "🎄", date : "01-01-" + moment().year()},
        {id : 0, text : "Martin Luther King Jr. Day", img : "🇺🇸", date : getHolidayDate(moment().year(), 0, 2, 1)},
        {id : 0, text : "Valentine's Day", img : "❤️", date : "14-02-" + moment().year()},
        {id : 0, text : "Easter Sunday", img : "❤️", date : Easter20ops("2024").format("DD-MM-YYYY")},
        {id : 0, text : "International Women’s Day", img : "💃", date : "08-03-" + moment().year()},
        {id : 0, text : "St. Patrick’s Day", img : "☘️", date : "17-03-" + moment().year()},
        {id : 0, text : "Holi", img : "🎉", date : "25-03-" + moment().year()},
        {id : 0, text : "Mother's Day", img : "🤰", date : getParentsDate(true)},
        {id : 0, text : "Father's Day", img : "👨", date : getParentsDate(false)},
        {id : 0, text : "Independence Day", img : "🇺🇸", date : "04-07-" + moment().year()},
        {id : 0, text : "Labor Day", img : "🛠️", date : "04-09-" + moment().year()},
        {id : 0, text : "Yom Kippur", img : "✡️️", date : "24-09-" + moment().year()},
        {id : 0, text : "Halloween", img : "🎃️", date : "31-10-" + moment().year()},
        {id : 0, text : "Veterans Day", img : "🎖️️", date : "10-11-" + moment().year()},
        {id : 0, text : "Diwali", img : "🔥", date : "12-11-" + moment().year()},
        {id : 0, text : "Thanksgiving Day", img : "🙏", date : getThanksGivingDate()},
        {id : 0, text : "Christmas Eve", img : "🎅🏽", date : "24-12-" + moment().year()},
        {id : 0, text : "Christmas Day", img : "🎅🏽", date : "25-12-" + moment().year()},
    ]
}