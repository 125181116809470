import './styles/app.css';
import { getCookie, setCookie } from './utils/CookieHelper';
import { useState, useEffect } from 'react';
import { getUrlParameter, getRequestUrl, getHttpParams, Pages } from './utils/Utils';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Auth from './pages/auth/Auth';
import Navbar from './pages/menu/Navbar';
import Landing from './pages/landing/Landing';
import Footer from './pages/landing/custom_views/Footer';
import Cards from './pages/cards/Cards';

function App() {
    const isTokenEmpty = (getCookie("token") == null || getCookie("token") == undefined || getCookie("token") == "")
    const isShowAuth = (window.location.pathname.includes(Pages.auth) && isTokenEmpty)
    const [user, setUser] = useState({})
        
    console.log("token  = " + getCookie("token"))
    return(
        <Router>
            <Navbar/>
            <div className="App">
                <Switch> 
                    <Route path={Pages.auth}>
                        <Auth/>
                    </Route>
                    <Route path={Pages.cards}>
                        <Cards/>
                    </Route>  
                    <Route path='*'>
                        <Landing/>
                    </Route>   
                </Switch>
            </div>
        </Router>
    )
    
}

export default App;

