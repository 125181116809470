import BaseDesktopDialog from "../../base/BaseDesktopDialog";
import { logOut } from "../../../utils/Utils";

function LogOutDialog(props) {
    const cancel = () => {
        props.setShow(false)
    }
    const ok = () => {
        props.setShow(false)
        logOut()
    }
    const getInnerHtml = () => {
        return(
            <div>
                <div className="dialog_title">You are about to be logged off</div>
                <div className="dialog_buttons cards_dialog_buttons">
                    <div onClick={cancel} className="dialog_cancel hover">Cancel</div>
                    <div onClick={ok} className="dialog_ok hover">OK</div>
                </div>
            </div>
        )
    }
    if (!props.isShow){
        return(<div/>)
    } 
    return(<BaseDesktopDialog isShow={props.isShow} setShow={props.setShow} getInnerHtml={getInnerHtml}/>)
    
}

export default LogOutDialog;
