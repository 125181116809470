import '../../styles/cards.css';
import { useState, useEffect, useCallback } from 'react';
import { getIsMobile, CookieConst, Pages, logOut } from '../../utils/Utils';
import Footer from '../landing/custom_views/Footer';
import { getCookie, setCookie } from '../../utils/CookieHelper';
import MyCards from './my_cards/MyCards';
import SentCards from './sent_cards/SentCards';
import LogOutDialog from './dialogs/LogOutDialog';

function Cards(props) {
    const [isMyCards, setMyCards] = useState(true)
    const [isShowLogOutDialog, setShowLogOutDialog] = useState(false)

    const signOut = () => {
        setShowLogOutDialog(!isShowLogOutDialog)
    }
    return(
        <div className="main">
            <div className="cards_container">
                <div className="cards_header"> 
                    <div className="cards_title">My cards</div>
                    <div className="cadrs_signed_in">Signed in as <span style={{color : '#878296'}}>{getCookie(CookieConst.clear_contact)}</span><span onClick={signOut} className="cards_sign_out hover">Sign out</span></div>
                </div>
                <div className="cards_toolbar_container">
                    <div style={isMyCards ? {} : {marginLeft : '123px'}} className="cards_toolbar_item_bg"></div>
                    <div onClick={() => setMyCards(true)} className="cards_toolbar_item hover">My cards</div>
                    <div onClick={() => setMyCards(false)} className="cards_toolbar_item hover">Sent gifts</div>
                </div>
                <div>
                    {isMyCards ? <MyCards isMyCards={isMyCards}/> : <SentCards isMyCards={isMyCards}/>}
                </div>
            </div>
            <LogOutDialog isShow={isShowLogOutDialog} setShow={setShowLogOutDialog}/>

            <Footer/>
        </div>
    )
}
export default Cards;