import '../../styles/menu.css';
import { useState, useEffect, useCallback } from 'react';
import logo from '../../img/menu/logo.svg'
import navbar_burger from '../../img/menu/navbar_burger.svg'
import navbar_close from '../../img/menu/navbar_close.svg'
import { Link } from "react-router-dom";
import { Pages } from '../../utils/Utils';
import { useHistory, useLocation } from "react-router-dom";
import FooterScrollLink from '../landing/custom_views/FooterScrollLinks';

function Navbar(props) {
    const history = useHistory()
    const location = useLocation()
    const [isToggled, setToggled] = useState(false)
    const openPage = (link) => {
        history.push(link)
    }
    //Pages.auth + '?sing_up=true'
    return(
        <div style={isToggled ? {maxHeight : '600px'} : {transition : 'none'}} className="navbar">
            <Link to='/'><img className="navbar-logo hover" src={logo}/></Link>
            <div className="navbar-items">
                {location.pathname == "/cards" ? <div className="navbar_cards hover">My Cards</div> : <Link to={Pages.sign_in}><div className="navbar-link navbar-link-mob hover">Sign in</div></Link>}
                {/* <Link to={Pages.sign_up}><div className="navbar-link navbar-link-mob hover">Sign up</div></Link> */}
                <FooterScrollLink title="How it works" to="how-it-works" className="navbar-link hover"/>
                <FooterScrollLink title="FAQ" to="faq-questions" className="navbar-link hover"/>
                <FooterScrollLink title="Contact us" to="need-help" className="navbar-link hover"/>
            </div>
            <div style={isToggled ? {opacity : 1, pointerEvents : 'auto'} : {opacity : 0, pointerEvents : 'none', zIndex : '-2', transition : 'none'}} className="navbar-items-mob">
                <div className="navbar-link">
                    <FooterScrollLink onClick={() => setToggled(!isToggled)} title="How it works" to="how-it-works" className="hover"/>
                </div>
                <div className="navbar-link">
                    <FooterScrollLink onClick={() => setToggled(!isToggled)} title="FAQ" to="faq-questions" className="hover"/>
                </div>
                <div className="navbar-link">
                    <FooterScrollLink onClick={() => setToggled(!isToggled)} title="Contact us" to="need-help" className="hover"/>
                </div>
            </div>
            <img onClick={() => setToggled(!isToggled)} className="navbar_burger hover" src={isToggled ? navbar_close : navbar_burger}/>
           
        </div>
    )
}
export default Navbar;